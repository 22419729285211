<template>
  <div>
    <el-form
      :model="searchForm"
      :inline="true"
      ref="searchForm"
      label-position="left"
      class="demo-form-inline"
    >
    <el-form-item label="仓库：" v-if="pageType == 2" >
      <el-select v-model="searchForm.warehouseName" placeholder="请选择仓库">
        <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.name"></el-option>
      </el-select>
    </el-form-item>
      <el-form-item label="商品名称">
        <el-input
          v-model="searchForm.name"
          placeholder="请输入商品名称"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="商品编号">
        <el-input v-model="searchForm.code" placeholder="请输入商品编号"></el-input>
      </el-form-item> -->
      <el-form-item>
        <el-button
          type="primary"
          @click="searchSubmit('searchForm')"
          icon="el-icon-search"
          >搜索</el-button
        >
        <el-button @click="resetForm('searchForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="mainTable">
      <div class="userTable boxMain">
        <p class="boxTitle">商品列表</p>
        <template>
          <el-table ref="multipleTable" :data="goodsList.tableData" height="300" :stripe="true" tooltip-effect="dark" style="width: 100%"
            v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" :selectable="selectable"> </el-table-column>
            <!-- <el-table-column prop="code" label="商品编号" show-overflow-tooltip>
            </el-table-column> -->
            <el-table-column prop="name" label="商品名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="describe" show-overflow-tooltip label="商品描述">
            </el-table-column>
            <!-- <el-table-column prop="color" label="颜色"> </el-table-column> -->
            <el-table-column prop="category" label="类型"> </el-table-column>
            <el-table-column prop="standard" label="规格"> </el-table-column>
            <el-table-column prop="model" label="型号"> </el-table-column>
            <el-table-column prop="unit" label="单位"> </el-table-column>
            <el-table-column prop="num" label="库存"> </el-table-column>
            <!-- <el-table-column prop="mfrs" label="制造商"> </el-table-column> -->
            <el-table-column prop="enabled" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.enabled == 0" style="color: red">禁用</p>
                <p v-else style="color: #67c23a">启用</p>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="remark" show-overflow-tooltip label="备注">
            </el-table-column> -->
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
      <div class="tree_component">
        <p class="boxTitle">快速查找</p>
        <el-tree :data="goodsType" :default-expand-all="true" :props="defaultProps" @node-click="handleNodeClick" style="height:360px;overflow-y: auto;"></el-tree>
      </div>
    </div>
    <div class="btns">
      <el-button
        type="primary"
        :disabled="goods.length == 0"
        @click="handleSubmit"
        >确定</el-button
      >
      <el-button @click="cancelAdd">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      goodsTypeId: [], //选择的商品类型id
      //搜索
      searchForm: {
        code:'',
        name: "",
        categoryId: "",
        unit: "",
        mfrs: "",
        enabled: "",
        limit: "2", //1：销售单 2：采购单
      },
      goodsList:{
        tableData: [],
      },
      goodsUnit: [], //商品单位
      goodsType: [], //商品类型
      // 商品状态
      goodsStatus: [
        {
          label: "禁用",
          id: "0",
        },
        {
          label: "启用",
          id: "1",
        },
      ],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      multipleTable: [],
      goods: [],
      pageName: this.fromPage,

      // 树形组件
      defaultProps: {
        children: 'childs',
        label: 'name'
      },
      warehouseList:[],
    };
  },
  // modelType 区分采购单/退货单
  props: ["fromPage", "warehouseName", "pageType","warehouseId","modelType"],
  created() {
    this.listEvent();
    this.getGoodsUnit();
    this.getGoodsType();
    this.getWarehouse();
  },
  methods: {
    //GET 仓库
    getWarehouse() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.warehouseList = res.data.data;
          }
        });
    },
    //点击确定后 取消表格的选择
    handleSubmit() {
      console.log(this.goods)
      this.$emit("goodsDataEvent", this.goods);
      this.$refs.multipleTable.clearSelection();
    },
    cancelAdd() {
      this.$emit("cancelDialog");
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    // 获取商品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
            "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          console.log(res.data.data)
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取商品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.searchForm.current = _this.pagesData.currentPage;
      _this.searchForm.size = _this.pagesData.currentRows;
      if (this.pageName == "warehouse") {
        _this.searchForm.limit = "";
      }
      let data = _this.searchForm;
      if(this.warehouseName){
        data = Object.assign({}, data, {
          warehouseName: this.warehouseName,
        })
      }
      if(this.$props.warehouseId){
        data.warehouseId = this.$props.warehouseId
      }
      data.mainGoods = 1
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsMall/selectByPage", {
          params: data,
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.loading = false;
            res.data.data.records.forEach((item) => {
              if (item.imgs) {
                item.icons = item.imgs.split(",")[0];
              }
              item.goodsName = item.name
              item.purchaseNum = ''
              item.purchasePrice = ''
            });
            _this.$set(_this.goodsList, 'tableData', res.data.data.records)
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent();
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = key == "limit" ? 2 : "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.goodsTypeId = [];
      this.listEvent();
    },
    handleSelectionChange(val) {
      console.log(val)
      this.goods = val;
    },
    // 树形组件点击事件
    handleNodeClick(data) {
      this.searchForm.categoryId = data.id;
      this.listEvent();
    },
    //复选框禁用
    selectable(row, rowIndex) {
      if(this.$props.modelType == '退货单' && row.num <=0){
        return false;  //禁用
      }else{
        return true; 
      }
    }
  },
};
</script>

<style  scoped>
.btns {
  padding-top: 30px;
  text-align: center;
}
.mainTable{
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.mainTable .userTable{
  width: 78%;
}
.mainTable .tree_component{
  width: 20%;
  padding-bottom: 20px;
  border: 1px solid #e5e5e5;
}
</style>
