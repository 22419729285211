<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="单据日期：">
          <el-date-picker v-model="time" :default-time="['00:00:00', '23:59:59']" @change="changeTime" value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择产品">
          <goods-change ref="goodsChange" @popupClick="getPopupGoods($event, 'searchForm')" @goodsSelectEvent="goodsDataEvent($event, 'searchForm')"></goods-change>
        </el-form-item>
        <el-form-item label="仓库">
          <el-select v-model="searchForm.warehouseId" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品类型">
          <el-cascader :options="goodsType" :show-all-levels="false" v-model="goodsTypeId" placeholder="请选择产品类型" :props="{
            checkStrictly: true,
            label: 'name',
            value: 'id',
            children: 'childs',
          }" clearable @change="cascaderChange($event,'searchForm')"></el-cascader>
        </el-form-item>
        <el-form-item label="业务类型">
          <el-select v-model="searchForm.type" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in receiveType" :key="index" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">产品收发汇总表</p>
        <div class="tableTopBtn">
          <el-button size="mini" type="primary" @click="exportData" v-if="exportButton == '1'" :disabled="tableData.length == 0">导出</el-button>
        </div>
        <template>
          <el-table ref="multipleTable" height="45vh" :data="tableData" show-summary :summary-method="getSummaries" tooltip-effect="dark" style="width: 100%;" v-loading="loading">
            <!-- <el-table-column prop="code" label="产品编码" width="100" align="center" fixed="left">
            </el-table-column> -->
            <el-table-column prop="goodsName" label="产品名称" min-width="150" align="center" fixed="left">
              <template slot-scope="scope">
                <router-link :to="{ name:'receiveDetailed',params:{goodsId:scope.row.goodsId}}" style="color:#409EFF">{{ scope.row.goodsName }}</router-link>
              </template>
            </el-table-column>
            <el-table-column prop="category" label="产品类别" align="center">
            </el-table-column>
            <el-table-column prop="standard" label="规格" align="center">
            </el-table-column>
            <el-table-column prop="unit" label="单位" align="center">
            </el-table-column>
            <el-table-column prop="warehouseName" label="仓库名称" align="center" min-width="120">
            </el-table-column>
            <!-- <el-table-column prop="form" label="业务类型" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.form == 1 ? '入库' : '出库' }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="关联订单类型" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.type == 1 ? '采购单' : '销售单' }}</p>
              </template>
            </el-table-column>      -->
            <el-table-column v-for="(item,index) in totalList" :key="index" :label="item.type == 1 ? '采购入库' : item.type == 2 ? '其他入库' : item.type == 3 ? '退货入库' : item.type == 4 ? '入库汇总' : item.type == 5 ? '销售出库' : item.type == 6 ? '其他出库' : item.type == 7 ? '出库汇总' : item.type == 8 ? '结余汇总' : item.type == 9 ? '采购退货' : ''" align="center">
              <el-table-column prop="num" label="数量" align="center">
                <template slot-scope="scope">
                  <p v-for="(tItem,i) in scope.row.warehouseInOutSummaryDTOList" :key="i">
                    <span v-if="tItem.type == item.type">{{ tItem.num }}</span>
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="compCost" label="成本" align="center" min-width="120px">
                <template slot-scope="scope">
                  <p v-for="(tItem,i) in scope.row.warehouseInOutSummaryDTOList" :key="i">
                    <span v-if="tItem.type == item.type">{{ tItem.compCost }} 元</span>
                  </p>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            当前第 {{ pagesData.currentPage }} 页，共 {{ pagesData.pages }} 页 {{ pagesData.total }} 条
            <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination> -->
          </div>
        </template>
      </div>

    </div>
    <!-- 导出 -->
    <el-dialog title="库存收发汇总表导出" :visible.sync="exportShow" width="30%" @close="exportPrintTotalShow = false;">
      <el-form :model="exportForm" ref="exportReceiveSummary" label-width="120px">
        <el-form-item label="选择产品">
          <goods-change ref="goodsChange" @popupClick="getPopupGoods($event, 'exportForm')" @goodsSelectEvent="goodsDataEvent($event, 'exportForm')" style="width:300px"></goods-change>
        </el-form-item>
        <el-form-item label="产品类型">
          <el-cascader :options="goodsType" :show-all-levels="false" v-model="exportForm.categoryId" placeholder="请选择产品类型" style="width:300px" :props="{
            checkStrictly: true,
            label: 'name',
            value: 'id',
            children: 'childs',
          }" clearable @change="cascaderChange($event, 'exportForm')"></el-cascader>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker v-model="exportForm.startTime" type="date" style="width: 300px" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker v-model="exportForm.endTime" type="date" style="width: 300px" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div style="text-align:center;padding-top:20px">
        <el-button type="primary" @click="exportSubmit">导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import goodsChange from "../../components/goodsChange.vue";
export default {
  data() {
    return {
      time: [],
      loading: true,
      //搜索
      searchForm: {
        startTime: "",
        endTime: "",
        warehouseId: "",
        goodsId: '',
        type: '',
        goodsTypeId: '',
      },
      tableData: [],
      warehouseData: [],

      multipleTable: [],
      ids: [],
      searchButton: '',
      exportButton: '',
      adcode: sessionStorage.getItem('adcode'),
      driverList: [],

      // 业务类型
      receiveType: [
        {
          name: '入库',
          value: 1
        }, {
          name: '出库',
          value: 2
        },
      ],

      // 产品类型
      goodsType: [],
      // 类型ID回显
      goodsTypeId: [],
      //循环表头数据
      headerList: [],
      // 合计数据
      totalList: [],
      pagesData: {
        pages: 0,
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      exportShow: false,
      exportForm: {},
      lazyFlag: true,
    }
  },
  created() {

    var privilege = JSON.parse(sessionStorage.getItem('authority'));
    privilege.forEach((item) => {
      if (item.authority == 'warehouse_query') {
        this.searchButton = '1'
        this.exportButton = '1'
      }
    });
  },
  components: {
    goodsChange
  },
  mounted() {
    this.getSelectGoods()
    this.getDriverList()
    this.getWarehouse()
    this.getGoodsType()
    this.listEvent()
    // 表格懒加载
    let table = this.$refs.multipleTable.$el;

    table.addEventListener('scroll', (res) => {

      if (Number((res.target.scrollTop).toFixed(2)) + res.target.clientHeight >= res.target.scrollHeight - 20) {
        if (this.lazyFlag == true && this.tableData.length < this.pagesData.total && this.pagesData.currentPage < this.pagesData.pages) {
          this.$set(this.pagesData, 'currentPage', this.pagesData.currentPage + 1)
          this.listEvent();
          this.lazyFlag = false
        }
      }
    }, true)
  },
  methods: {
    // 多级联动change事件
    cascaderChange(e, formName) {
      this[formName].categoryId = e[e.length - 1];
    },
    // 获取产品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    renderHeader(column, index) {
      console.log(column, index, "column, index")
    },
    // 获取司机下拉列表
    getDriverList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/driver/getDriver")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.driverList = res.data.data;
          }
        });
    },
    // 搜索产品多选
    searchGoodsMore(e) {
      this.searchForm.goodsId = e.join(',')
    },
    goSalesDelivery(row) {
      this.$router.push({ name: "salesReturn", params: row })
    },
    //合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let nums = []
      let compCosts = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (column.property == 'num') {
          nums.push(index)
        }
        if (column.property == 'compCost') {
          compCosts.push(index)
        }
      });
      nums.forEach((item, index) => {
        sums[item] = this.totalList[index].num
      })
      compCosts.forEach((items, i) => {
        sums[items] = this.totalList[i].compCost + ' 元'
      })
      return sums;
    },
    getSelectGoods(warehouseId) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            warehouseId,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let list = JSON.parse(JSON.stringify(res.data.data))
            list.forEach(item => {
              item.name = item.name + ' ' + item.standard;
            });
            _this.exportGoods = list;
          }
        });
    },
    changeTime(e) {
      this.searchForm.startTime = e[0]
      this.searchForm.endTime = e[1]
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      this.loading = true
      this.searchForm.size = _this.pagesData.currentRows
      this.searchForm.current = _this.pagesData.currentPage
      this.$axios.get(_this.$axios.defaults.basePath + '/warehouseGoodsRelStatistics/goodsInOutDetailSummary', {
        params: _this.searchForm
      }).then(function (res) {
        console.log(res)
        if (res.data.errcode == 0) {
          _this.loading = false;
          _this.totalList = res.data.data.itemList;
          _this.$nextTick(() => {
            if (_this.$refs.multipleTable && _this.$refs.multipleTable.doLayout) {
              _this.$refs.multipleTable.doLayout()
            }
          })
          if (_this.tableData.length == 0) {
            _this.tableData = res.data.data.dataList.records;
          } else {
            _this.tableData = _this.tableData.concat(res.data.data.dataList.records);
          }
          _this.pagesData.total = res.data.data.dataList.total;
          _this.pagesData.pages = res.data.data.dataList.pages;
          if (_this.lazyFlag == false && _this.pagesData.currentPage == _this.pagesData.pages) {
            _this.$message({
              message: '已经是最后一页了',
              type: 'info'
            })

          }
          _this.$nextTick(() => {
            _this.lazyFlag = true
            _this.$refs.multipleTable.doLayout()
          })
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }

      })
    },
    //GET 仓库
    getWarehouse() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.warehouseData = res.data.data;
          }
        });
    },
    //搜索操作
    searchSubmit() {
      if (!this.searchForm.goodsId) {
        let goodsName = this.$refs.goodsChange.searchName;
        this.$set(this.searchForm, 'goodsName', goodsName)
      }
      this.$set(this.pagesData, 'currentPage', 1)
      this.tableData = []
      this.listEvent()
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach(key => { this.searchForm[key] = '' })
      this.$set(this.searchForm, 'goodsId', '')
      this.$set(this.searchForm, 'warehouseId', '')
      this.$set(this.searchForm, 'type', '')
      this.$set(this.searchForm, 'goodsTypeId', '')
      this.$set(this.searchForm, 'startTime', '')
      this.$set(this.searchForm, 'endTime', '')
      this.$set(this.searchForm, 'goodsName', '')
      this.time = []
      this.$refs.goodsChange.goodsName = ''
      this.tableData = []
      this.listEvent()
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach(item => {
        _this.ids.push(item.id);
      });
    },
    getPopupGoods(data, formName) {
      this.$set(this[formName], 'goodsId', data.id)
    },
    goodsDataEvent(data, formName) {
      let goodsIds = [];
      let names = []
      data.forEach(item => {
        goodsIds.push(item.id)
        names.push(item.goodsName)
      })
      this.$refs.goodsChange.goodsName = names.join(',')
      this.$set(this[formName], 'goodsId', goodsIds.join(','))
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      this.pagesData.currentPage = val;
      this.listEvent();
    },
    exportData() {
      this.exportShow = true;
    },
    exportSubmit() {
      let _this = this;
      console.log(_this.exportForm)
      // return;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouseGoodsRelStatistics/goodsInOutDetailSummaryExport", {
          params: _this.exportForm,
          responseType: 'blob'
        })
        .then((res) => {
          console.log(res)
          var eleLink = document.createElement("a");
          eleLink.download = "库存收发汇总表.csv";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          console.info(blob);
          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.exportShow = false;
          _this.$message({
            message: "导出成功",
            type: "success",
          });
          _this.exportForm = {};
          // 然后移除
          document.body.removeChild(eleLink);
        })

    },
  }
}
</script>

<style  scoped>
.orderId {
  color: #409eff;
  cursor: pointer;
}

.boxMain >>> .el-table .el-table__footer-wrapper tr td .cell {
  font-weight: 600;
}
</style>