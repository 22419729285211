<template>
  <div class="goodsPage">
    <div :ref="'autocomplate' + mainIndex">
      <el-autocomplete popper-class="my-autocomplete" v-model="goodsName" :fetch-suggestions="querySearch"
        placeholder="请输入内容" @select="handleSelect" @blur="autocomplateBlur" @focus="autocomplateFocus($event, mainIndex)"
        @input="autocomplateInput">
        <i class="el-icon-more el-input__icon" slot="suffix" style="cursor: pointer;" @click="handleIconClick">
        </i>
      </el-autocomplete>
    </div>
    <!-- 输入建议弹框 -->
    <div class="input_popup" :style="{ 'left': popupLeft,'top': popupTop }" ref="popup" v-if="popupShow">
      <el-table :data="popupGoods" style="width: 100%" header-row-class-name="t-header" height="260"
        :row-class-name="rowClassName" @row-click="popupRowClick">
        <el-table-column type="index" width="50">
        </el-table-column>
        <!-- <el-table-column prop="code" label="商品编号" width="180">
        </el-table-column> -->
        <el-table-column prop="name" label="商品名称" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="standard" label="规格">
        </el-table-column>
        <el-table-column prop="unit" label="单位">
        </el-table-column>
        <el-table-column prop="num" label="库存">
          </el-table-column>
        <el-table-column prop="distributorId" label="供应商">
        </el-table-column>
      </el-table>
    </div>
    <el-dialog width="70%" title="选择商品" :visible.sync="goodsDialogShow" custom-class="addPurchaseDialog" append-to-body>
      <purchaseGoods :pageType="1" :modelType="modelType" :warehouseId="warehouseId" @cancelDialog="cancelDialog"
        @goodsDataEvent="getPurchaseGoods">
      </purchaseGoods>
    </el-dialog>
  </div>
</template>

<script>
import purchaseGoods from "../components/purchaseGoods.vue";
export default {
  data() {
    return {
      goodsName:'',
      popupShow:false,
      popupLeft:'',
      popupTop:'',
      popupGoods: [],
      searchName:'',
      // 商品查询组件
      goodsDialogShow:false,
    };
  },
  components: {
    purchaseGoods
  },
  watch:{
    nowRowData: {
      handler(newVal, oldVal) {
        if(newVal){
          this.goodsName = newVal.goodsName
        }
      },
      deep: true,
      immediate: true,
    }
  },
  props: {
    mainIndex:{
      type:Number
    },
    pageType:{
      type:String
    },
    nowRowData:{
      type:Object
    },
    // 选择商品时先选择仓库
    warehouseId:{
      type: String
    },
    // 区分采购单/退货单
    modelType: {
      type: String
    },
  },
  created() {
    this.getGoodsList();
  },
  mounted(){
    if(this.nowRowData){
      this.goodsName = this.nowRowData.goodsName
    }
  },
  methods: {
    // 获取商品列表
    getGoodsList() {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            goodsName: _this.goodsName,
            warehouseId:_this.$props.warehouseId,
            mainGoods:1
          },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            res.data.data.forEach(item => {
              item.goodsName = item.name
              item.purchaseNum = ''
              item.purchasePrice = ''
            })
            _this.popupGoods = res.data.data
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 自定义输入框--商品名称
    querySearch(queryString, cb) {
      console.log(queryString,this.$props.warehouseId)
      // this.$props.modelType == '退货单' && 
      this.searchName = queryString
      if (this.$props.modelType && !this.$props.warehouseId) {
        this.$message({
          message:'请先选择仓库',
          type:'warning'
        })
        return
      }
      var restaurants = this.popupGoods;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(this.popupGoods);

      /**
       * 输入建议弹出框定位
       * **/
      // 获取窗口宽高
      let winW = window.innerWidth || document.documentElement.clientWidth;
      let winH = window.innerHeight || document.documentElement.clientHeight;
      // 获取当前输入框的位置及宽高
      let inputData = this.$refs['autocomplate' + this.$props.mainIndex].getBoundingClientRect();
      this.popupLeft = inputData.left + 'px'
      if (winH > inputData.top + 300) {
        this.popupTop = inputData.top + inputData.height + 'px'
      } else {
        this.popupTop = inputData.top - 260 + 'px'
      }
      this.popupShow = true
    },
    createFilter(queryString) {
      return (restaurant) => {
        // return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      console.log(item);
    },
    // 当输入框获取焦点时显示弹框
    autocomplateFocus(e, index) {
      
      // this.getGoodsList();
      // this.mainTableIndex = index
      // this.getGoodsList()
    },
    // 当输入框失去焦点时隐藏弹框
    autocomplateBlur() {
      let _this = this;
      setTimeout(()=>{
        _this.popupShow = false
      },500)
    },
    // 输入框输入时
    autocomplateInput(e) {
      // if (this.$props.modelType == '退货单' && !this.$props.warehouseId) {
      //   this.$message({
      //     message: '请先选择仓库',
      //     type: 'warning'
      //   })
      //   return
      // }
      console.log(e)
      this.searchName = e
      this.goodsName = e
      if(!e){
        this.$emit('inputChange', '')
      }
      this.getGoodsList()
    },
    // 弹出商品筛选弹框
    handleIconClick(ev) {
      // this.$props.modelType == '退货单' && 
      if (this.$props.modelType && !this.$props.warehouseId) {
        this.$message({
          message: '请先选择仓库',
          type: 'warning'
        })
        return
      }
      this.popupShow = false
      this.goodsDialogShow = true
    },
    rowClassName({ row, rowIndex }) {
      row.rowIndex = rowIndex
    },
    // 弹窗表格行点击事件
    popupRowClick(row, column, event) {
      this.goodsName = row.goodsName
      this.searchName = row.goodsName
      this.$emit('popupClick', row,this.mainIndex)
      this.popupShow = false
    },



    //子组件传过来的取消选择商品弹框事件
    cancelDialog() {
      this.goodsDialogShow = false;
    },
    // 子组件传递数据
    getPurchaseGoods(goods) {
      console.log(goods)
      if(!goods){return}
      this.$emit('goodsSelectEvent',goods)
      
      this.goodsDialogShow = false
      if (this.$props.pageType) {
        this.goodsName = goods[0].goodsName
      }else{
        let name = []
        goods.forEach(item=>{
          name.push(item.goodsName)
        })
        this.goodsName = name.join(',')
      }
    },
  },
};
</script>

<style lang="scss"  scoped>
.goodsPage>>>.my-autocomplete {
  display: none !important;
  width: auto !important;
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
.input_popup {
  position: fixed;
  z-index: 10000;
  border: 1px solid #e5e5e5;
}
.goodsPage>>>.el-autocomplete-suggestion{
  display: none !important;
}
</style>
